function showPopup() {
    $(".opening-link").click(function () {
      var popup = $(this).find("#popup-modal");
      $('#popup-modal').addClass("show");
  
      $('#popup-modal').click(function (event) {
        console.log(event);
        console.log(popup);
        //event.stopPropagation();
        $('#popup-modal').removeClass("show")
      });
    });
  }
  
export { showPopup };
  