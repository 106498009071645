function headerMov() {

  const headerElement = document.querySelector(".navbar");
  const mobilNavLogoContainer = document.querySelector(".navbar__logo-mobil");
  if (headerElement) {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        headerElement.classList.add("navbar--fixed");
        mobilNavLogoContainer.classList.add("navbar__logo-mobil-scroll");
      } else {
        headerElement.classList.remove("navbar--fixed");
        mobilNavLogoContainer.classList.remove("navbar__logo-mobil-scroll");
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();
  }
}

export { headerMov };
