function gesundheitsMagazinSlider() {
  let owlgesundheitsMagazin = jQuery(".magazin-slider");

  owlgesundheitsMagazin.owlCarousel({
    //animateOut: 'fadeOut',
    nav: true,
    navText: [
      '<span class="owl-carousel-arrow-left"><img src="/wp-content/themes/bergauf/assets/img/layout/arrow.svg" alt="Slider Icon"/></span>',
      '<span class="owl-carousel-arrow-right"><img src="/wp-content/themes/bergauf/assets/img/layout/arrow.svg" alt="Slider Icon"/></span>',
    ],
    mouseDrag: false,
    touchDrag: true,
    dotsEach: true,
    loop: false,
    dots: true,
    margin: 20,
    stagePadding: 75,
    autoplay: false,
    smartSpeed: 750,
    stagePadding: 0,
    items: 3,
    lazyLoad: true,
    responsive: {
      0: {
        items: 1,
      },
      992: {
        items: 2,
      },
      1440: {
        items: 3,
      },
    },
  });
}

export { gesundheitsMagazinSlider };
