function teaserslider() {
  $(".teaser-slider").owlCarousel({
    margin: 30,
    lazyLoad: true,
    nav: true,
    navText: [
      '<span class="owl-carousel-arrow-left"><img src="/wp-content/themes/bergauf/assets/img/layout/arrow.svg" alt="Slider Icon"/></span>',
      '<span class="owl-carousel-arrow-right"><img src="/wp-content/themes/bergauf/assets/img/layout/arrow.svg" alt="Slider Icon"/></span>',
    ],
    mouseDrag: false,
    touchDrag: true,
    dotsEach: true,
    loop: true,
    dots: false,
    margin: 20,
    stagePadding: 75,
    autoplay: true,
    smartSpeed: 750,
    stagePadding: 0,
    items: 3,
    lazyLoad: true,
    responsive: {
      0: {
        items: 1,
      },
      992: {
        items: 2,
      },
      1440: {
        items: 3,
      },
    },
  });

  $(".teaser-slider").each(function () {
    var highestBox = 0;
    $(".content-wrapper", this).each(function () {
      if ($(this).height() > highestBox) {
        highestBox = $(this).height();
      }
    });
    $(".content-wrapper", this).height(highestBox);
  });
}

export { teaserslider };
