let accordionHeadings = document.querySelectorAll(".accordion-heading");
let accordionHeadingsLength = accordionHeadings.length;

function accStateChange() {
  if (accordionHeadings != undefined) {
    for (let i = 0; i <= accordionHeadingsLength; i++) {
      $(".accordion-heading-" + i).on("click", function () {
        $(this).toggleClass("accordion-heading-active");

        $(".accordion-heading")
          .not(this)
          .removeClass("accordion-heading-active");

        $(".accordion-body")
          .not(
            $(this)
              .parents(".accordion")
              .children(".accordion-body-" + i)
          )
          .slideUp();
        $(this)
          .parents(".accordion")
          .children(".accordion-body-" + i)
          .slideToggle();
      });
    }
  }
}
export { accStateChange };

let accordionHeadingsSub = document.querySelectorAll(".accordion-subheading");
let accordionHeadingsLengthSub = accordionHeadingsSub.length;

function accStateChangeSub() {
  if (accordionHeadingsSub != undefined) {
    for (let i = 0; i <= accordionHeadingsLengthSub; i++) {
      $(".accordion-subheading-" + i).on("click", function () {
        $(this).toggleClass("accordion-subheading-active");

        $(".accordion-subheading")
          .not(this)
          .removeClass("accordion-subheading-active");

        $(".accordion-subbody")
          .not(
            $(this)
              .parents(".accordion")
              .children(".accordion-subbody-" + i)
          )
          .slideUp();
        $(this)
          .parents(".accordion")
          .children(".accordion-subbody-" + i)
          .slideToggle();
      });
    }
  }
}
export { accStateChangeSub };
