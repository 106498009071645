/* General + Third-Party */
import jQuery from "jquery";
import { lazyload } from "./globals/lazyload";
import "owl.carousel";

/* Layout-Parts */
import { headerMov } from "./layout/header";
import { toggleHamburger } from "./layout/navigation";
import { navCloseOnClick } from "./layout/navigation";
import { toggleChildren } from "./layout/navigation";
import { showPopup } from "./layout/popup";
/* Blocks */
// import { my-function } from '../../blocks/BLOCKNAME/script';
import { employeeIsotopeSorter } from "../../blocks/employee-sort/script";
import { sortButtonActiveState } from "../../blocks/employee-sort/script";
import { accStateChangeEmployee } from "../../blocks/employee-sort/script";
import { gesundheitsMagazinSlider } from "../../blocks/slider-magazine/script";
import { mediShopSlider } from "../../blocks/slider-medi-shop/script";
import { heroSlider } from "../../blocks/slider-hero/script";
import { accStateChange } from "../../blocks/accordion/script";
import { accStateChangeSub } from "../../blocks/accordion/script";
import { teaserslider } from "../../blocks/teaser-slider/script";
import { textsliderteaser } from "../../blocks/text-bild-slider/script";

jQuery(document).ready((_) => {
  /*Third-party */
  lazyload();

  /*Layout */
  headerMov();
  toggleHamburger();
  navCloseOnClick();
  toggleChildren();
  showPopup();

  /*Blocks */
  heroSlider();
  textsliderteaser();
  sortButtonActiveState();
  accStateChangeEmployee();
  employeeIsotopeSorter();
  gesundheitsMagazinSlider();
  mediShopSlider();
  accStateChange();
  accStateChangeSub();
  teaserslider();
});
