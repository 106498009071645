function heroSlider() {
  let owlHeroSlider = jQuery(".hero-slider-wrapper");
  //var itemCount = jQuery(owl).find('.stage').length;

  owlHeroSlider.owlCarousel({
    //animateOut: 'fadeOut',
    nav: false,
    mouseDrag: false,
    touchDrag: true,
    dotsEach: true,
    loop: true,
    dots: true,
    margin: 25,
    stagePadding: 50,
    autoplayTimeout: 10000,
    autoplay: true,
    smartSpeed: 1000,
    stagePadding: 0,
    items: 1,
    lazyLoad: true,
    responsive: {
      0: {
        items: 1,
      },
      992: {
        items: 1,
      },
      1440: {
        items: 1,
      },
    },
  });
}

export { heroSlider };
